<template>
  <div id="water">
    <div class="water-text">水利管理</div>
    <div class="cartogram-list">
      <div>
        <!-- 水利 -->
        <div class="Lattic-circular">
          <div class="lattic-style">
            <div id="bao-a" ref="echarts"></div>
          </div>
          <div class="circular-style">
            <div>
              <p>{{ small_reservoir.name }}</p>
            </div>
            <div>
              <div class="container" :style="{ '--skew': skew + 'deg' }">
                <div
                  class="type-box"
                  v-for="(item, index) in dataLista"
                  :key="index"
                >
                  <div class="type-progress">
                    <div class="progress" :style="{ width: item.rate }"></div>
                  </div>
                  <div class="type-num">{{ item.rate }}</div>
                </div>
              </div>
            </div>
            <div>
              <p>
                应巡查天数<span>{{ small_reservoir.days }}</span
                >&nbsp;&nbsp;&nbsp;已巡查天数<span>{{
                  small_reservoir.day
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <!-- 河道 -->

        <div class="Lattic-circular">
          <div class="lattic-style">
            <div id="bao-b" ref="echartsb"></div>
          </div>
          <div class="circular-style">
            <div>
              <p>{{ river.name }}</p>
            </div>
            <div>
              <div class="container" :style="{ '--skew': skew + 'deg' }">
                <div
                  class="type-box"
                  v-for="(item, index) in dataListb"
                  :key="index"
                >
                  <div class="type-progress">
                    <div class="progress" :style="{ width: item.rate }"></div>
                  </div>
                  <div class="type-num">{{ item.rate }}</div>
                </div>
              </div>
            </div>
            <div>
              <p>
                应巡查天数<span>{{ river.days }}</span
                >&nbsp;&nbsp;&nbsp;已巡查天数<span>{{ river.day }}</span>
              </p>
            </div>
          </div>
        </div>
        <!-- 农村供水 -->
        <div class="Lattic-circular">
          <div class="lattic-style">
            <div id="bao-c" ref="echartsc"></div>
          </div>
          <div class="circular-style">
            <div>
              <p>{{ drinking_water.name }}</p>
            </div>
            <div>
              <div class="container" :style="{ '--skew': skew + 'deg' }">
                <div
                  class="type-box"
                  v-for="(item, index) in dataListc"
                  :key="index"
                >
                  <div class="type-progress">
                    <div class="progress" :style="{ width: item.rate }"></div>
                  </div>
                  <div class="type-num">{{ item.rate }}</div>
                </div>
              </div>
            </div>
            <div>
              <p>
                应巡查天数<span>{{ drinking_water.days }}</span
                >&nbsp;&nbsp;&nbsp;已巡查天数<span>{{
                  drinking_water.day
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>
<style lang="less" csoped>
#water {
  width: 520px;
  height: 426px;
  display: flex;
  flex-direction: column;
}
.water-text {
  color: #15abcc;
  font-size: 18px;
  margin-left: 35px;
  width: 100%;
}
#bao-a {
  width: 100%;
  height: 100%;
}
#bao-b {
  width: 100%;
  height: 100%;
}
#bao-c {
  width: 100%;
  height: 100%;
}
.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-content: space-between;
  background: #040f2f;
}
.type-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 2px solid #6478b7;
}

.type-name {
  width: 60px;
  font-size: 12px;
  color: #fff;
}

.type-progress {
  position: relative;
  flex: 1;
  height: 80%;
  transform: skew(var(--skew));
}

.type-progress::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.type-progress::after {
  z-index: 1;
  background: linear-gradient(to right, transparent 90%, #030e2f 0%) 0 0/20%
    100% repeat-x;
}

.progress {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #031d56, #43e6f5);
}
.type-num {
  width: 45px;
  font-size: 16px;
  color: #fff;
}
</style>
<script>
export default {
  props: {
    skew: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      small_reservoir: {},
      river: {},
      drinking_water: {},
      dataLista: [{ name: "濮阳县", value: "", rate: "" }],
      dataListb: [{ name: "濮阳县", value: "", rate: "" }],
      dataListc: [{ name: "濮阳县", value: "", rate: "" }],
    };
  },
  mounted() {
    this.$axios.get("https://dqh.nbqy.top/api/home").then((res) => {
      this.small_reservoir = res.data.data.small_reservoir_and_pool;
      (this.dataLista[0].rate =
        res.data.data.small_reservoir_and_pool.percent > "100"
          ? "100%"
          : res.data.data.small_reservoir_and_pool.percent + "%"),
        this.getEcha();
      this.river = res.data.data.river;
      (this.dataListb[0].rate =
        res.data.data.river.percent > "100"
          ? "100%"
          : res.data.data.river.percent + "%"),
        this.getEchb();
      this.drinking_water = res.data.data.drinking_water;
      (this.dataListc[0].rate =
        res.data.data.drinking_water.percent > "100"
          ? "100%"
          : res.data.data.drinking_water.percent + "%"),
        this.getEchc();
    });
  },
  methods: {

    // 

    getEcha() {
      var chartDom = this.$echarts.init(this.$refs.echarts);
      const gaugeData = [
        {
          value:
            this.small_reservoir.percent > "100"
              ? "100"
              : this.small_reservoir.percent,

          title: {
            offsetCenter: ["0%", "0%"],
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "0%"],
          },
        },
      ];
      let option = {
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,

              itemStyle: {
                color: "#4af1f8",
              },
            },
            axisLine: {
              lineStyle: {
                width: 5,
                color: [[1, "#00237e"]],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: gaugeData,

            detail: {
              color: "#fff",
              fontSize: 15,
              formatter: "{value}%",
            },
          },
          {
            type: "pie",

            center: ["50%", "50%"],
            radius: ["50%", "50%"],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 9,
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: "#61bad3",
                  },
                },
              },
            ],
          },
        ],
      };
      chartDom.setOption(option);
    },

    // 河道

    getEchb() {
      var chartDom = this.$echarts.init(this.$refs.echartsb);
      const gaugeData = [
        {
          value:
            this.small_reservoir.percent > "100"
              ? "100"
              : this.small_reservoir.percent,

          title: {
            offsetCenter: ["0%", "0%"],
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "0%"],
          },
        },
      ];
      let option = {
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,

              itemStyle: {
                color: "#4af1f8",
              },
            },
            axisLine: {
              lineStyle: {
                width: 5,
                color: [[1, "#00237e"]],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: gaugeData,

            detail: {
              color: "#fff",
              fontSize: 15,
              formatter: "{value}%",
            },
          },
          {
            type: "pie",

            center: ["50%", "50%"],
            radius: ["50%", "50%"],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 9,
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: "#61bad3",
                  },
                },
              },
            ],
          },
        ],
      };
      chartDom.setOption(option);
    },

    // 农村供水

    getEchc() {
      var chartDom = this.$echarts.init(this.$refs.echartsc);
      const gaugeData = [
        {
          value:
            this.drinking_water.percent > "100"
              ? "100"
              : this.drinking_water.percent,

          title: {
            offsetCenter: ["0%", "0%"],
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "0%"],
          },
        },
      ];
      let option = {
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,

              itemStyle: {
                color: "#4af1f8",
              },
            },
            axisLine: {
              lineStyle: {
                width: 5,
                color: [[1, "#00237e"]],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: gaugeData,

            detail: {
              color: "#fff",
              fontSize: 15,
              formatter: "{value}%",
            },
          },
          {
            type: "pie",

            center: ["50%", "50%"],
            radius: ["50%", "50%"],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 9,
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: "#61bad3",
                  },
                },
              },
            ],
          },
        ],
      };
      chartDom.setOption(option);
    },
  },
};
</script>