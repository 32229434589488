<template>
<div>
   <div class="text-dom">
      <div>山</div>
      <div class="title">季度巡查率</div>
    </div>
  <div id="Mountain"></div>
</div>
</template>
<style scoped lang="less">
#Mountain {
  width: 550px;
  height: 370px;
  // margin-top: 20px;
}

.text-dom{
  padding: 0 50px ;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #15abcc;
  .title{
    color: #fff;
    margin: 35px 0 0 0;
    font-size: 20px;
  }
}
</style>
<script>
export default {
  mounted() {
    var chartDom = this.$echarts.init(document.getElementById("Mountain"));
    let option = {


      xAxis: {
        show: false,
        type: "value",
        splitLine: {},
      },
      yAxis: {
        type: "category",
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: "#fff",
          fontSize: "15",
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        data: ["第四季度", "第三季度", "第二季度", "第一季度"],
      },
 grid: {
        left: '10',            // 固定左边刻度宽度
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
      series: [
        {
          name: "",
          type: "bar",
          barWidth: 20,
          // barGap:'30%',
          barCategoryGap: "80",
          data: [50, 20, 30, 40,],
          itemStyle: {
            barBorderRadius: [15],
            color: {
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(3,245,211, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(18,141,183, 1)",
                },
              ],
              global: false,
            },
          },
        },
      ],
    };
    chartDom.setOption(option);
  },
};
</script>