<template>
  <div id="app">
    <div ref="newCatalog" id="bigScreen">
      <header>
        <Header />
      </header>
      <main>
        <div class="top">
          <MainTop />
        </div>
        <div class="bottom">
          <MainBottom />
        </div>
      </main>
      <footer>
        <!-- <img src="./img/154556.png" alt="" class="" /> -->
        技术支持：中国电信鄞州分公司
      </footer>
   
    </div>
  </div>
</template>

<style lang="less" csoped>
@import "./assets/css/reset.less";
// @import "./assets/css/index.less";
#app {
  background-image: url(./img/12333.png);
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

header {
  height: 107px;
  padding-top: 20px;
}
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .top {
    width: 100%;
    height: 446px;
  }
  .bottom {
    width: 100%;
    height: 446px;
  }
}
footer {
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  // margin-top: 10px;
  margin-right: 40px;
  text-align: right;
  // img {
  //   float: right;
  //   margin-right: 30px;
  //   margin-top: 2px;
  // }
}

#bigScreen {
  width: 1920px;
  height: 1080px;
  display: flex;
  flex-direction: column;
}
</style>
<script type="text/javascript">
import Header from "./views/header.vue";
import MainTop from "./views/main/MainTop.vue";
import MainBottom from "./views/main/MainBottom.vue";
import Land from "./views/main/MaBoEcharts/land.vue";
import Forest from "./views/main/MaBoEcharts/Forest.vue";
import Field from "./views/main/MaTecharts/field.vue"
export default {
  components: {
    Header,
    MainTop,
    MainBottom,
    Land,
    Forest,
    Field
  },
  data() {
    return {};
  },
  mounted() {
    this.setBodyCss();
    this.resize();
    window.addEventListener("resize", () => {
      this.resize();
    });
  },
  methods: {
    setBodyCss() {
      document.body.style.width = "1920px";
      document.body.style.height = "1080px";
      document.body.style.transform = "scale(1, 1)";
      document.body.style.transformOrigin = "left top 0px";
      document.body.style.overflow = "hidden";
    },
    resize() {
      this.resizeCenter();
      this.resizeFullBak();
    },
    resizeCenter() {
      if (!window.screen.height || !window.screen.width)
        return this.resizeCenterBak();
      let ratio = window.innerHeight / window.screen.height;
      document.body.style.transform = "scale(" + ratio + ")";
      document.body.style.transformOrigin = "left top";
    },
    resizeCenterBak() {
      let ratioX = window.innerWidth / document.body.offsetWidth;
      let ratioY = window.innerHeight / document.body.offsetHeight;
      let ratio = Math.min(ratioX, ratioY);
      document.body.style.transform = "scale(" + ratio + ")";
      document.body.style.transformOrigin = "left top";
    },
    resizeFullBak() {
      let ratioX = window.innerWidth / document.body.offsetWidth;
      let ratioY = window.innerHeight / document.body.offsetHeight;
      document.body.style.transform = "scale(" + ratioX + ", " + ratioY + ")";
      document.body.style.transformOrigin = "left top";
    },
  },
};
</script>

