<template>
  <div id="field">
    <div style=" width: 100%;">
    <p class="text">田</p>
    </div>
    <div class="list-main">
      <div class="Lattic-circular">
        <div class="lattic-style">
          <!-- <Clrcular/> -->
            <div id="bao-d" ref="echartsd"></div>
          
        </div>
        <div class="circular-style">
            <div>
              <p>{{ farmland.name }}</p>
            </div>
            <div>
              <div class="container" :style="{ '--skew': skew + 'deg' }">
                <div
                  class="type-box"
                  v-for="(item, index) in dataListd"
                  :key="index"
                >
                  <div class="type-progress">
                    <div class="progress" :style="{ width: item.rate }"></div>
                  </div>
                  <div class="type-num">{{ item.rate }}</div>
                </div>
              </div>
            </div>
            <div>
              <p>
                应巡查天数<span>{{ farmland.days }}</span
                >&nbsp;&nbsp;&nbsp;已巡查天数<span>{{
                  farmland.day
                }}</span>
              </p>
            </div>
          </div>
      </div>

    </div>

  <div class="list-main">
      <div class="Lattic-circular">
        <div class="lattic-style">
            <div id="bao-e" ref="echartse">
              
            </div>
          
        </div>
        <div class="circular-style">
            <div>
              <p>{{ rectification.name }}</p>
            </div>
            <div>
              <div class="container" :style="{ '--skew': skew + 'deg' }">
                <div
                  class="type-box"
                  v-for="(item, index) in dataListe"
                  :key="index"
                >
                  <div class="type-progress">
                    <div class="progress" :style="{ width: item.rate }"></div>
                  </div>
                  <div class="type-num">{{ item.rate }}</div>
                </div>
              </div>
            </div>
            <div>
              <p>
                累计发现问题：<span>{{ rectification.percent }}</span
                >
              </p>
            </div>
          </div>
      </div>

    </div>
  </div>
</template>
<style lang="less" csoped>

#field{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  
}
.text {
  color: #15abcc;
  font-size: 18px;
  margin-left: 80px;
  margin-top: 10px;
}
.list-main{
  width: 550px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


} 
.Lattic-circular{
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;

}
.lattic-style{
  width: 120px;
  height: 120px;

}
.circular-style{
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
 justify-content:space-evenly;
 
  p{
    color: #fff;
    font-size: 15px;
    span{
      color: #26a1c0;
      font-size: 16px;
    }
  }

}
#bao-d,
#bao-e {
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-content: space-between;
  background: #040f2f;
}
.type-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 2px solid #6478b7;
}

.type-name {
  width: 60px;
  font-size: 12px;
  color: #fff;
}

.type-progress {
  position: relative;
  flex: 1;
  height: 80%;
  transform: skew(var(--skew));
}

.type-progress::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.type-progress::after {
  z-index: 1;
  background: linear-gradient(to right, transparent 90%, #030e2f 0%) 0 0/20%
    100% repeat-x;
}

.progress {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #031d56, #43e6f5);
}
.type-num {
  width: 45px;
  font-size: 16px;
  color: #fff;
}




</style>
<script>
export default {

  data(){
    return{
      farmland: {},
        rectification: {},
      dataListd: [{ name: "濮阳县", value: "", rate: "" }],
      dataListe: [{ name: "濮阳县", value: "", rate: "100%" }],
    }


  },
   mounted() {
    this.$axios.get("https://dqh.nbqy.top/api/home").then((res) => {
      console.log(res.data.data,'get,123')
      this.farmland = res.data.data.farmland;
      (this.dataListd[0].rate =
        res.data.data.farmland.percent > "100"
          ? "100%"
          : res.data.data.farmland.percent + "%"),
        this.getEchd();

    this.rectification = res.data.data.rectification;
    //   (this.dataListe[0].rate =
    //     res.data.data.rectification.percent > "100"
    //       ? "100%"
    //       : res.data.data.rectification.percent + "%"),
        this.getEche();
    });
  },
  methods:{
      getEchd() {
      var chartDom = this.$echarts.init(this.$refs.echartsd);
      const gaugeData = [
        {
          value:
            this.farmland.percent > "100"
              ? "100"
              : this.farmland.percent,

          title: {
            offsetCenter: ["0%", "0%"],
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "0%"],
          },
        },
      ];
      let option = {
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,

              itemStyle: {
                color: "#4af1f8",
              },
            },
            axisLine: {
              lineStyle: {
                width: 5,
                color: [[1, "#00237e"]],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: gaugeData,

            detail: {
              color: "#fff",
              fontSize: 15,
              formatter: "{value}%",
            },
          },
          {
            type: "pie",

            center: ["50%", "50%"],
            radius: ["50%", "50%"],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 9,
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: "#61bad3",
                  },
                },
              },
            ],
          },
        ],
      };
      chartDom.setOption(option);
    },

  getEche() {
      var chartDom = this.$echarts.init(this.$refs.echartse);
      const gaugeData = [
        {
          value:100,
            // this.rectification.percent > "100"
            //   ? "100"
            //   : this.rectification.percent,

          title: {
            offsetCenter: ["0%", "0%"],
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "0%"],
          },
        },
      ];
      let option = {
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,

              itemStyle: {
                color: "#4af1f8",
              },
            },
            axisLine: {
              lineStyle: {
                width: 5,
                color: [[1, "#00237e"]],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: gaugeData,

            detail: {
              color: "#fff",
              fontSize: 15,
              formatter: "{value}%",
            },
          },
          {
            type: "pie",

            center: ["50%", "50%"],
            radius: ["50%", "50%"],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 9,
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: "#61bad3",
                  },
                },
              },
            ],
          },
        ],
      };
      chartDom.setOption(option);
    },
  }
};
</script>