<template>
  <div class="mbottom">
    <div class="mbottom-left">
      <Forest />
    </div>
    <div class="mbottom-rieght">
      <Land />
    </div>
  </div>
</template>
<style lang="less" csoped>
.mbottom {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .mbottom-left,
  .mbottom-rieght {
    width: 920px;
    height: 446px;
    background: url(../../img/1356.png);
    background-size: 100% 100%;
  }
  .mbottom-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<script>
import Forest from "./MaBoEcharts/Forest.vue";
import Land from "./MaBoEcharts/land.vue";
export default {
  name: "mabottom",
  components: {
    Forest,
    Land,
  },
};
</script>