<template>
    <div class="mtop">
        <div class="shan-tyle">
            <Mountain/>
        </div>
        <div class="shui-tyle"> 
            <Water/>
        </div>
        <div class="tian-tyle">
            <Field/>
        </div>
    </div>
</template>
<style lang="less" csoped>
    .mtop{
        display: flex;
        flex-direction: row;
         justify-content: space-evenly;
        .shan-tyle,
        .shui-tyle,
        .tian-tyle{
            width: 600px;
            height: 446px;   
            background: url(../../img/1356.png);  
            background-size: 100% 100%;
            display:flex;
            justify-content: center;
            align-items: center;
                
        }

    }
</style>
<script>
import Mountain from "./MaTecharts/mountain.vue"
import Water from "./MaTecharts/water.vue"
import Field from "./MaTecharts/field.vue"
export default {
    name:"maintop",
    components:{
        Mountain,
        Water,
        Field,
    }

    
}
</script>

