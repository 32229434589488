<template>
<div>
   <div class="text-dom">
      <div>林</div>
      <div class="title">年度巡查率</div>
    </div>
  <div id="lin"></div>
</div>
</template>
<style scoped lang="less" >
#lin {
  width: 900px;
  height: 376px;
}
.text-dom{
  padding: 0 90px ;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #15abcc;
  .title{
    color: #fff;
    margin: 35px 0 0 0;
    font-size: 20px;
  }
}
</style>
<script>
export default {
  mounted() {
    var chartDom = this.$echarts.init(document.getElementById("lin"));
    let option = {
      xAxis: [
        {
          show: true,
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          }, 
          axisLabel: {
            show: true,
            color: "#fff",
            fontSize: "20",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              width: 2,
              type: "solid",
            },
          },
        },
      ],
      yAxis: [
        {
          show: true,
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: "#fff",
            fontSize: "20",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              width: 2,
              type: "solid",
            },
          },
        },
      ],
      series: [
        {
          type: "bar",
          barWidth: 20,
          data: [20, 15, 26, 21, 15, 19, 24, 15, 23, 20, 15, 22],
          itemStyle: {
            barBorderRadius: [0, 0, 0, 0],
            color: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(0,102,253,1)",
                },
                {
                  offset: 1,
                  color: "rgba(1,253,254, 1)",
                },
              ],
              global: false,
            },
          },
        },
      ],
    };
    chartDom.setOption(option);
  },
};
</script>