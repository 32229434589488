<template>
  <div id="di">
    <div class="text-dom">
      <div>地</div>
      <div class="title">年度巡查率</div>
    </div>
    <div id="main"></div>
  </div>
</template>
<style lang="less" csoped>
#di {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}
.text-dom{
  padding: 0 90px ;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #15abcc;
  .title{
    color: #fff;
    margin: 35px 0 0 0;
    font-size: 20px;
  }
}
#main {
  width: 100%;
  height: 80%;
}
</style>
<script>
export default {
  mounted() {
    var chartDom = this.$echarts.init(document.getElementById("main"));
    let option = {
      legend: {
        itemGap: 150,
        itemHeight: 15,
        textStyle: {
          color: "#fff",
          fontSize: "16px",
        },
        itemWidth: 40,
        data: ["巡查员1", "巡查员2", "巡查员3"],
      },
      xAxis: {
        type: "category",
        data: ["1月", "2月", "3月", "4月", "5月", "6月","7月", "8月", "9月", "10月", "11月", "12月"],
        axisTick: {
          show: false,
        },
        axisLabel: {
          margin: 30,
          show: true,
          color: "#fff",
          fontSize: "12",
          interval: 0,
        },
      },
      yAxis: {
        type: "value",
        csale: true,
        max: 100,
        min: 0,
        splitNumber: 5,
        axisLine: {
          show: true,
          lineStyle: {
            width: 2,
            type: "solid",
          },
        },
        axisLabel: {
          margin: 30,
          show: true,
          color: "#fff",
          fontSize: "12",
        },
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: "巡查员3",
          type: "line",
          data: [40, 55, 40, 55, 40, 65,40, 55, 40, 55, 50, 55],
          smooth: true,
          // symbol: "circle",
          color:'#018cd4',
          symbolSize: 10,
          itemStyle: {
            normal: {
              lineStyle: {
                width: 3,
                type: "dotted", 
              },
            },
          },
        },
        {
          name: "巡查员2",
          type: "line",
          data: [60, 65, 60, 65, 70, 65,60, 65, 60, 75, 60, 65],
          color:"#12d4a9",
          symbol: "roundRect",
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 1,
              x2: 0,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(12,34,75,0)",
                },
                {
                  offset: 0.9,
                  color: "rgba(21,212,169,0.3)",
                },
              ],
              global: false,
            },
          },
        },

        {
          name: "巡查员1",
          type: "line",
          data: [80, 85, 75, 85, 90, 85,80, 85, 80, 95, 80, 85],
          symbol: "roundRect",
          color:'#ff8736'
        },
      ],
    };

    chartDom.setOption(option);
  },
};
</script>